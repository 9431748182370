import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
/** @jsx jsx needed to get Storybook to render */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import MobileMenu from './MobileMenu';
import useLocalize from '../../../hooks/useLocalize';
import { LocaleContext } from '../../../context/LocaleContext';

const menuQuery = graphql`
  {
    sanityMenu(menuName: { eq: "Main Menu" }) {
      _rawMenuDrawers(resolveReferences: { maxDepth: 10 })
    }
    sanityGlobalMenu(globalMenuName: { eq: "Global Menu" }) {
      _rawGlobalMenuLinks(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const GatsbyMobileMenu = ({ menuColor }) => {
  const rawData = useStaticQuery(menuQuery) || {};
  const { locale } = useContext(LocaleContext);
  const { sanityMenu = {}, sanityGlobalMenu = {} } = useLocalize(
    rawData,
    locale
  );
  const { _rawMenuDrawers = [] } = sanityMenu;
  const { _rawGlobalMenuLinks = [] } = sanityGlobalMenu;

  return (
    <MobileMenu
      _rawMenuDrawers={_rawMenuDrawers}
      _rawGlobalMenuLinks={_rawGlobalMenuLinks}
      menuColor={menuColor}
    />
  );
};

GatsbyMobileMenu.propTypes = {
  menuColor: PropTypes.string,
};

GatsbyMobileMenu.defaultProps = {
  menuColor: 'primary',
};

export default GatsbyMobileMenu;
