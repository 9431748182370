/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { unitConverter as uc } from '../../../../styles/base';

const NavbarItem = ({
  buttonCss,
  children,
  index,
  menuCss,
  onMouseEnter,
  title,
}) => {
  const divCss = css`
    position: absolute;
    top: ${uc('39px')};
    left: 50%;
    transform: translateX(-50%);
    perspective: ${uc('1500px')};
  `;

  const onMouseEnterHandler = () => {
    onMouseEnter(index);
  };

  return (
    <div
      css={menuCss}
      onMouseEnter={onMouseEnterHandler}
      onFocus={onMouseEnterHandler}
    >
      <button type="button" css={buttonCss}>
        {title}
      </button>
      <div css={divCss}>{children}</div>
    </div>
  );
};

NavbarItem.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  menuCss: PropTypes.shape({}).isRequired,
  buttonCss: PropTypes.shape({}).isRequired,
};

export default NavbarItem;
