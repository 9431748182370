import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import sanityImage from '../../../utils/sanityImage';

import Link from '../../atoms/Link/Link';
import Text from '../../atoms/Text/Text';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  unitConverter as uc,
} from '../../../styles/base';

const MenuQuote = ({
  attribution,
  children,
  link,
  logoImage,
  logoImageWidth,
  personImage,
  personImageWidth,
}) => {
  const menuQuoteCss = css`
    display: ${personImage && personImage.asset ? 'grid' : 'block'};
    grid-template-columns: 1fr 1fr;
    padding: ${uc('8px 30px 30px')};
    color: ${colors.darkGray.three};
    font-family: ${fontFamilies.proximaSoft};
    text-decoration: none;

    @media (${breakpoints.mobile}) {
      grid-template-columns: none;
      padding: ${uc('20px')};
    }

    :visited {
      color: ${colors.darkGray.three};
    }

    .left {
      width: 100%;
      padding-top: ${uc('13px')};

      ${!(personImage && personImage.asset) &&
        `
        .company-logo {
          max-width: 40%;
          display: inline-block;
        }

        p {
          display: inline-block;
          padding-left: ${uc('20px')};
        }

        @media (${breakpoints.mobile}) {
          .company-logo {
            max-width: 20%;
            width: auto;
            margin: 0 auto;
            vertical-align: top;
          }

          p {
            max-width: 80%;
          }
        }
      `}

      @media (${breakpoints.mobile}) {
        margin: 0 auto;
      }
    }

    .company-logo {
      display: ${personImage && personImage.asset ? 'block' : 'inline-block'};
      width: ${personImage && personImage.asset ? uc(logoImageWidth) : 'auto'};
    }

    .right {
      align-self: end;
      width: ${uc(personImageWidth)};
      justify-self: end;

      @media (${breakpoints.mobile}) {
        width: auto;

        img {
          display: none;
        }
      }
    }

    p {
      max-width: ${uc('220px')};
      font-family: ${fontFamilies.proximaSoft};
      line-height: 1.25;

      @media (${breakpoints.mobile}) {
        max-width: auto;
      }
    }
  `;

  return (
    <Link to={link} css={menuQuoteCss}>
      <div className="left">
        {logoImage && logoImage.asset && (
          <div className="company-logo">
            <img
              src={sanityImage(logoImage)
                .auto('format')
                .url()}
              alt="company logo"
              title="company logo"
            />
          </div>
        )}
        <Text fontSize={fontSizes.fourteen} color={colors.lightGray.five}>
          &quot;
          {children}
          &quot; &nbsp;-&nbsp;
          {attribution}
        </Text>
      </div>
      {personImage && personImage.asset && (
        <div className="right">
          <img
            src={sanityImage(personImage)
              .auto('format')
              .url()}
            alt="quote attribution"
            title="quote attribution"
          />
        </div>
      )}
    </Link>
  );
};

MenuQuote.propTypes = {
  children: PropTypes.node.isRequired,
  logoImage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  logoImageWidth: PropTypes.string,
  personImage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  personImageWidth: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  attribution: PropTypes.string,
};

MenuQuote.defaultProps = {
  logoImage: {},
  logoImageWidth: `${uc('112px')}`,
  personImage: {},
  personImageWidth: `${uc('145px')}`,
  link: 'https://csod.com',
  attribution: 'unknown',
};

export default MenuQuote;
