import PropTypes from 'prop-types';
import { useState } from 'react';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { Flipper } from 'react-flip-toolkit';
import Navbar from './Navbar/Navbar';
import NavbarItem from './Navbar/NavbarItem';
import DropdownContainer from './DropdownContainer/DropdownContainer';
import {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  menuBreakpoints,
  menuColors,
  unitConverter as uc,
} from '../../../styles/base';
import MenuSection from './MenuSection/MenuSection';
import AngledButton from '../../atoms/Buttons/AngledButton/AngledButton';
import Button from '../../atoms/Buttons/Button/Button';

const Menu = ({
  _rawMenuDrawers,
  buttonText,
  buttonUrl,
  duration,
  menuColor,
  renderedGlobalMenu,
  renderSearchModal,
  routeName,
  useAngleOnHomepage,
}) => {
  const flipperCss = css`
    margin-right: ${uc('20px')};

    @media (${menuBreakpoints.completeSwitchToMobile}) {
      width: 100%;
      margin-right: ${uc('84px')};
    }
  `;

  const menuCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: ${uc('0 20px')};

    &::before {
      position: absolute;
      top: 100%;
      left: -12.5%;
      display: none;
      width: 150%;
      height: 170%;
      content: '';
    }

    &:hover,
    &:active,
    &:focus {
      &::before {
        display: block;
      }

      > div {
        opacity: 1;
        pointer-events: auto;
      }

      button {
        color: ${menuColors.whiteHover};
      }
    }

    [data-first-dropdown-section='true'] {
      width: auto;
      max-width: ${uc('510px')};

      p {
        min-width: ${uc('330px')};
      }
    }

    &:nth-of-type(4) {
      @media (${menuBreakpoints.collapseCommunityMenu}) {
        display: none;
      }
    }

    &:nth-of-type(5) {
      .drawer-wrapper {
        transform: translateX(-70%);
      }

      ul {
        &::after {
          left: 70%;
        }
      }

      @media (${menuBreakpoints.collapseCompanyMenu}) {
        display: none;
      }
    }

    @media (${menuBreakpoints.completeSwitchToMobile}) {
      display: none;
    }
  `;

  const demoCss = css`
    height: auto;
    margin: 0;
    padding: ${uc('10px 15px')};
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.sixteen};
    font-family: ${fontFamilies.roboto};
    line-height: ${lineHeights.solid};

    @media (${menuBreakpoints.completeSwitchToMobile}) {
      display: none;
    }
  `;

  const drawerCss = css`
    li {
      margin-bottom: 0;
      list-style-type: none;
    }
  `;

  const navCss = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  `;

  // change link color depending on input
  const buttonCss = css`
    /* position above the dropdown, otherwise the dropdown will cover up the bottom sliver of the buttons */
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: ${uc('1px 7px 2px')};
    color: ${menuColor ? menuColors[menuColor] : menuColors.white};
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.sixteen};
    font-family: ${fontFamilies.roboto};
    background: transparent;
    border: 0;
    cursor: pointer;
    transition: opacity 250ms;

    &:hover,
    &:focus {
      outline: none;
      opacity: 0.7;
    }
  `;

  const [activeIndices, setActiveIndices] = useState([]);
  const [animatingOut, setAnimatingOut] = useState(null);
  const [animatingOutTimeout, setAnimatingOutTimeout] = useState(null);

  const divCss = css`
    position: relative;
    z-index: 1;
  `;

  const menuDrawer = drawers => (
    <ul>
      {drawers.map((properties, index) => {
        const { _key } = properties;
        if (index === 0) {
          return (
            <div key={_key} css={divCss} data-first-dropdown-section>
              <MenuSection {...properties} />
            </div>
          );
        }
        return (
          <div key={_key} css={divCss}>
            <MenuSection {...properties} />
          </div>
        );
      })}
    </ul>
  );

  const navbarConfig = _rawMenuDrawers.map(drawer => ({
    key: drawer._key,
    title: drawer.menuDrawerName,
    dropdown: () => menuDrawer(drawer.menuSections),
    triangleColor:
      drawer.menuSections[0].menuSectionConfig.backgroundColor === 'white'
        ? 'white'
        : 'gray',
  }));

  const resetDropdownState = i => {
    setActiveIndices(typeof i === 'number' ? [i] : []);
    setAnimatingOut(false);
    setAnimatingOutTimeout(null);
  };

  const onMouseEnter = i => {
    if (animatingOutTimeout) {
      clearTimeout(animatingOutTimeout);
      resetDropdownState(i);
      return;
    }
    if (activeIndices[activeIndices.length - 1] === i) {
      return;
    }

    setActiveIndices(activeIndices.concat(i));
    setAnimatingOut(false);
  };

  const onMouseLeave = () => {
    setAnimatingOut(true);
    setAnimatingOutTimeout(setTimeout(resetDropdownState, duration));
  };

  let CurrentDropdown;
  let PrevDropdown;
  let direction;

  const currentIndex = activeIndices[activeIndices.length - 1];
  const prevIndex =
    activeIndices.length > 1 && activeIndices[activeIndices.length - 2];

  if (typeof currentIndex === 'number')
    CurrentDropdown = navbarConfig[currentIndex].dropdown;
  if (typeof prevIndex === 'number') {
    PrevDropdown = navbarConfig[prevIndex].dropdown;
    direction = currentIndex > prevIndex ? 'right' : 'left';
  }

  const buttonToRender = () => {
    if (routeName === 'Homepage' && useAngleOnHomepage) {
      return (
        <AngledButton
          _key="demo-menu"
          angle="top"
          color="accent"
          css={demoCss}
          link={buttonUrl}
          buttonText={buttonText}
        />
      );
    }
    return (
      <Button color="accent" css={demoCss} link={buttonUrl} _key="demo-menu">
        {buttonText}
      </Button>
    );
  };

  return (
    <Flipper flipKey={currentIndex} spring="noWobble" css={flipperCss}>
      <Navbar onMouseLeave={onMouseLeave}>
        <div onMouseLeave={onMouseLeave} css={navCss}>
          {navbarConfig.map((drawer, index) => (
            <NavbarItem
              key={`navbar-${drawer.key}`}
              title={drawer.title}
              index={index}
              menuColor={menuColor}
              menuCss={menuCss}
              buttonCss={buttonCss}
              onMouseEnter={onMouseEnter}
            >
              <ul key={drawer.key} css={drawerCss}>
                {currentIndex === index && (
                  <DropdownContainer
                    direction={direction}
                    animatingOut={animatingOut}
                    duration={duration}
                    triangleColor={drawer.triangleColor}
                  >
                    <CurrentDropdown />
                    {PrevDropdown && <PrevDropdown />}
                  </DropdownContainer>
                )}
              </ul>
            </NavbarItem>
          ))}
        </div>
        {renderSearchModal}
        {renderedGlobalMenu}
        {buttonToRender()}
      </Navbar>
    </Flipper>
  );
};

Menu.propTypes = {
  _rawMenuDrawers: PropTypes.arrayOf(PropTypes.shape({})),
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  duration: PropTypes.number,
  menuColor: PropTypes.string,
  renderedGlobalMenu: PropTypes.shape({}),
  renderSearchModal: PropTypes.shape({}),
  routeName: PropTypes.string,
  useAngleOnHomepage: PropTypes.bool,
};

Menu.defaultProps = {
  _rawMenuDrawers: [],
  buttonText: 'get demo',
  buttonUrl: null,
  duration: 300,
  menuColor: 'primary',
  renderedGlobalMenu: null,
  renderSearchModal: null,
  routeName: '',
  useAngleOnHomepage: false,
};

export default Menu;
