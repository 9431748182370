/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useContext } from 'react';
import GatsbyMenu from '../Menu/GatsbyMenu';
import PageWrapper from '../PageWrapper/PageWrapper';
import {
  breakpoints,
  menuColors,
  unitConverter as uc,
} from '../../../styles/base';
import GatsbyMobileMenu from '../MobileMenu/GatsbyMobileMenu';
import MobileSearch from '../MobileSearch/MobileSearch';
import { LocaleContext } from '../../../context/LocaleContext';
import Link from '../../atoms/Link/Link';
/* import sanityJson from '../../../../../backend/sanity.json';
import { getLocale } from '../../../../../backend/config/intlConfig'; 

const locale = getLocale(sanityJson.api.dataset); */

const Header = ({ menuColor, routeName }) => {
  const { locale } = useContext(LocaleContext);

  const csodLogo =
    locale === 'us'
      ? '/logo/csod-logo-realize-potential.svg'
      : '/logo/csod-logo-svg.svg';
  const headerCss = css`
    position: absolute;
    z-index: 50;
    width: 100vw;
    margin: ${uc('57px auto 0')};

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .-immersive-mode & {
      opacity: 0;
    }

    @media (${breakpoints.largeTablet}) {
      min-height: ${uc('60px')};
      margin-top: ${uc('15px')};
    }
  `;

  const logoCss = css`
    padding-left: ${uc('20px')};
    fill: ${menuColors ? menuColors[menuColor] : menuColors.primary};

    svg {
      width: ${uc('228px')};
      height: ${uc('57.8px')};
    }

    .st0 {
      fill: ${menuColors ? menuColors[menuColor] : menuColors.primary};
    }
  `;

  return (
    <header css={headerCss}>
      <PageWrapper>
        <Link to="/">
          <span css={logoCss}>
            <SVG src={csodLogo} alt="logo" />
          </span>
        </Link>
        <GatsbyMenu
          duration={300}
          menuColor={menuColor}
          routeName={routeName}
        />
        <GatsbyMobileMenu menuColor={menuColor} />
        <MobileSearch />
      </PageWrapper>
    </header>
  );
};

Header.propTypes = {
  menuColor: PropTypes.string,
  routeName: PropTypes.string,
};

Header.defaultProps = {
  menuColor: 'primary',
  routeName: '',
};

export default Header;
