import PropTypes from 'prop-types';
import { useState } from 'react';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx, keyframes } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  menuBreakpoints,
  menuColors,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading2, Heading3 } from '../../atoms/Headings/Headings';
import MenuSection from '../Menu/MenuSection/MenuSection';
import Link from '../../atoms/Link/Link';

const MobileMenu = ({ _rawGlobalMenuLinks, _rawMenuDrawers, menuColor }) => {
  const mobileMenuCss = css`
    right: ${uc('20px')};
    z-index: 10;
    display: none;

    @media (${menuBreakpoints.completeSwitchToMobile}) {
      display: block;
    }
  `;

  // uses 1127px as a breakpoint because that's when the menu starts to wrap,
  // so we need to start using MobileMenu then
  const heading2Css = css`
    position: absolute;
    right: 0;
    display: none;
    width: ${uc('24px')};
    height: ${uc('3px')};
    margin-right: ${uc('30px')};
    background: ${menuColor ? menuColors[menuColor] : menuColors.white};
    border-radius: ${uc('1px')};

    &::before,
    &::after {
      position: absolute;
      width: ${uc('24px')};
      height: ${uc('3px')};
      background: ${menuColor ? menuColors[menuColor] : menuColors.white};
      border-radius: ${uc('1px')};
      content: '';
    }

    &::before {
      top: ${uc('9px')};
      right: 0;
    }

    &::after {
      top: ${uc('-9px')};
      right: 0;
    }

    @media (${menuBreakpoints.collapseCompanyMenu}) {
      display: block;
      font-size: 0;

      &:active,
      &:focus,
      &:hover {
        background: ${colors.primary};

        &::before,
        &::after {
          background: ${colors.primary};
        }
      }
    }
  `;

  // animation keyframes
  const popoutKF = keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 100%;
    }
  `;

  // entire mobile menu box
  const mobileMenuContainerCss = css`
    display: none;
    max-width: 100%;

    color: ${colors.primary};
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.sixteen};
    font-family: ${fontFamilies.proximaSoft};
    box-shadow: ${shadows.large};

    @media (${menuBreakpoints.completeSwitchToMobile}) {
      position: absolute;
      top: ${uc('10px')};
      right: ${uc('15px')};
      display: none;
      max-width: 100%;
      padding-top: ${uc('50px')};
      padding-bottom: ${uc('20px')};
      background: ${colors.white};

      &.open {
        z-index: 10;
        display: block;
        width: ${uc('440px')};
        max-width: 94%;
        animation: 0.5s ${popoutKF} ease;
      }
    }
  `;

  const menuSectionKF = keyframes`
    0% {
      max-height: 0;
    }

    100% {
      max-height: ${uc('800px')};
    }
  `;

  const menuSectionCss = css`
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    color: ${colors.primary};
    text-align: left;
    background: ${colors.white};
    border: none;
    outline: none;

    > span {
      display: block;
      padding: ${uc('10px 0 10px 40px')};
    }

    ul {
      display: block;
      width: 100%;
      max-width: 100%;
      max-height: 0;
      overflow: hidden;
      list-style-type: none;
      outline: none;
    }

    .icon-link-subheading {
      padding: ${uc('0 20px')};
    }

    &.open {
      ul {
        max-height: ${uc('800px')};
        margin-bottom: ${uc('10px')};
        padding-bottom: ${uc('10px')};
        border-bottom: 1px solid ${colors.lightGray.three};
        animation: 0.5s ${menuSectionKF} ease;
      }

      > span {
        color: ${colors.white};
        background: ${colors.primary};
      }
    }
  `;

  const closeButtonCss = css`
    position: absolute;
    top: 0;
    right: 0;
    width: ${uc('51px')};
    height: ${uc('51px')};
    font-size: 0;
    background: ${colors.white};
    border: none;
    cursor: pointer;

    &::after,
    &::before {
      position: absolute;
      top: ${uc('24px')};
      right: ${uc('14px')};
      left: ${uc('14px')};
      height: ${uc('3px')};
      background: ${colors.primary};
      border-radius: ${uc('1px')};
      transform: rotate(45deg);
      transition: background 0.1s;
      content: '';
    }

    &::after {
      transform: rotate(-45deg);
    }
  `;

  const globalMenuDrawerCss = css`
    padding-top: ${uc('15px')};
  `;

  const globalMenuLinkCss = css`
    margin: 0;
    padding-left: ${uc('30px')};
    color: ${colors.primary};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.copy};
    letter-spacing: ${uc('1px')};

    &:hover {
      color: ${colors.primaryHover};
    }

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.fifteen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fifteen};
    }
  `;

  const [menuState, setMenuState] = useState(false);
  const [openMenuSectionId, setOpenMenuSectionId] = useState(null);

  // open / close main mobile menu
  const toggleMobileMenu = () => {
    setMenuState(!menuState);
  };

  // only show menu section if current section, otherwise hide
  const toggleMenuSection = id => () => {
    setOpenMenuSectionId(openMenuSectionId === id ? null : id);
  };

  const menuDrawer = drawers => (
    <ul>
      {drawers.map(drawer => (
        <MenuSection key={`drawer-${drawer._key}`} {...drawer} />
      ))}
    </ul>
  );

  const globalMenuDrawer = menuLinks => (
    <ul css={globalMenuDrawerCss}>
      {menuLinks.map(menuLink => (
        <li key={`menu-link-${menuLink._key}`}>
          {menuLink.link && menuLink.link.length ? (
            <Link to={menuLink} css={globalMenuLinkCss}>
              {menuLink.text}
            </Link>
          ) : (
            <Heading3 css={globalMenuLinkCss}>{menuLink.text}</Heading3>
          )}
        </li>
      ))}
    </ul>
  );

  // create the menu
  const partialNavbar = _rawMenuDrawers.map(drawer => ({
    key: drawer._key,
    title: drawer.menuDrawerName,
    dropdown: () => menuDrawer(drawer.menuSections),
  }));

  const globalSection = {
    key: 'global',
    title: 'Global Sites',
    dropdown: () => globalMenuDrawer(_rawGlobalMenuLinks),
  };

  // add global menu to the rest of the menu
  const navbarConfig = [...partialNavbar, globalSection];

  // render the menu sections
  const renderContainers = navbarConfig.map((drawer, index) => {
    const CurrentDropdown = drawer.dropdown;
    return (
      <button
        key={`menu-section-${drawer.key}`}
        id={`menu-section-${index}`}
        onClick={toggleMenuSection(index)}
        className={index === openMenuSectionId ? 'open' : 'closed'}
        css={menuSectionCss}
        type="submit"
      >
        <span>{drawer.title}</span>
        <CurrentDropdown />
      </button>
    );
  });

  return (
    <div css={mobileMenuCss}>
      <Heading2 onClick={toggleMobileMenu} css={heading2Css}>
        mobile menu
      </Heading2>
      <nav
        className={menuState ? 'open' : 'closed'}
        css={mobileMenuContainerCss}
      >
        <button css={closeButtonCss} type="submit" onClick={toggleMobileMenu}>
          Close
        </button>
        {renderContainers}
      </nav>
    </div>
  );
};

MobileMenu.propTypes = {
  _rawMenuDrawers: PropTypes.arrayOf(PropTypes.shape({})),
  _rawGlobalMenuLinks: PropTypes.arrayOf(PropTypes.shape({})),
  menuColor: PropTypes.string,
};

MobileMenu.defaultProps = {
  _rawMenuDrawers: [],
  _rawGlobalMenuLinks: [],
  menuColor: 'primary',
};

export default MobileMenu;
