import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { colors, sectionSettings } from '../../../../../styles/base';
import IconLinkSubheading from '../../../../molecules/IconLinkSubheading/IconLinkSubheading';

const IconLinkSubheadingSection = ({
  iconLinkSubheadings,
  menuSectionConfig,
}) => {
  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const sectionCss = css`
    padding-left: ${menuSectionConfig.leftPadding
      ? sectionSettings.menuPadding
      : '0px'};
    background: ${backgroundColor};
  `;

  return (
    <div css={sectionCss}>
      {iconLinkSubheadings.map(item => {
        const { _key } = item;
        return <IconLinkSubheading key={_key} {...item} />;
      })}
    </div>
  );
};

IconLinkSubheadingSection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  iconLinkSubheadings: PropTypes.arrayOf(PropTypes.shape({})),
};

IconLinkSubheadingSection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  iconLinkSubheadings: [],
};

export default IconLinkSubheadingSection;
