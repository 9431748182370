import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  sectionSettings,
  unitConverter as uc,
} from '../../../../../styles/base';
import InlineLinkSubheading from '../../../../molecules/InlineLinkSubheading/InlineLinkSubheading';

const InlineLinkSection = ({ inlineLinkSectionItems, menuSectionConfig }) => {
  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const sectionCss = css`
    padding-left: ${menuSectionConfig.leftPadding
      ? sectionSettings.menuPadding
      : '0px'};
    background: ${backgroundColor};

    @media (${breakpoints.largeTablet}) {
      padding: ${uc('10px 0')};
    }
  `;

  const renderedLinks = inlineLinkSectionItems.map((item, index) => {
    const inlineLinkIndex = `iconLinkIndex-${index}`;
    return <InlineLinkSubheading {...item} key={inlineLinkIndex} />;
  });

  return <div css={sectionCss}>{renderedLinks}</div>;
};

const inlineLinkSubheadingShape = {
  link: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

InlineLinkSection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  inlineLinkSectionItems: PropTypes.arrayOf(
    PropTypes.shape(inlineLinkSubheadingShape)
  ),
};

InlineLinkSection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  inlineLinkSectionItems: [],
};

export default InlineLinkSection;
