import { useState } from 'react';
import { navigate } from 'gatsby';
import useLink from './useLink';

export default function useGoToSearch() {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const href = useLink(`/search?q=${value}`, true);
  const onChange = e => {
    if (error) {
      setError('');
    }

    setValue(e.target.value);
  };
  const onSearch = e => {
    e.preventDefault();
    if (value) {
      navigate(href);
    } else {
      setError('Please, tell us what you are looking for');
    }
  };
  return { value, error, onChange, onSearch };
}
