import { forwardRef } from 'react';
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { jsx, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const getFadeContainerKeyFrame = ({ animatingOut, direction }) => {
  if (!direction) return null;
  return keyframes`
    to {
      transform: translateX(0);
      opacity: ${animatingOut ? 0 : 1};
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0) scale(1.0, 1.0);
      transform: translateZ(0);
    }
  `;
};

const FadeContainer = styled.div`
  top: 0;
  left: 0;
  opacity: ${props => (props.direction && !props.animatingOut ? 0 : 1)};
  animation-name: ${getFadeContainerKeyFrame};
  animation-duration: ${props => props.duration}ms;
  animation-fill-mode: forwards;
`;

const FadeContents = forwardRef(
  ({ animatingOut, children, direction, duration }, ref) => (
    <FadeContainer
      // prevent screen readers from reading out hidden content
      aria-hidden={animatingOut}
      animatingOut={animatingOut}
      direction={direction}
      duration={duration}
      ref={ref}
    >
      {children}
    </FadeContainer>
  )
);

FadeContents.propTypes = {
  animatingOut: PropTypes.bool,
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['left', 'right', null]),
  duration: PropTypes.number.isRequired,
};

FadeContents.defaultProps = {
  animatingOut: false,
  direction: null,
};

export default FadeContents;
