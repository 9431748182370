import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  sectionSettings,
  unitConverter as uc,
} from '../../../../../styles/base';
import MenuQuote from '../../../../molecules/MenuQuote/MenuQuote';

const MenuQuoteSection = ({
  attribution,
  link,
  logoImage,
  logoImageWidth,
  menuSectionConfig,
  personImage,
  personImageWidth,
  quoteText,
}) => {
  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const sectionCss = css`
    width: ${uc('435px')};
    padding-left: ${menuSectionConfig.leftPadding
      ? sectionSettings.menuPadding
      : '0px'};
    background: ${backgroundColor};
  `;

  const sectionWithNoPersonImageCss =
    personImage && personImage.asset
      ? {}
      : css`
          @media (${breakpoints.mobile}) {
            width: 100%;
          }
        `;

  const renderedLinks = (
    <MenuQuote
      attribution={attribution}
      personImage={personImage}
      personImageWidth={personImageWidth}
      logoImage={logoImage}
      logoImageWidth={logoImageWidth}
      link={link}
    >
      {quoteText}
    </MenuQuote>
  );

  return (
    <div css={[sectionCss, sectionWithNoPersonImageCss]}>{renderedLinks}</div>
  );
};

MenuQuoteSection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  quoteText: PropTypes.string.isRequired,
  logoImage: PropTypes.shape({}),
  logoImageWidth: PropTypes.string,
  personImage: PropTypes.shape({ asset: PropTypes.shape({}) }),
  personImageWidth: PropTypes.string,
  link: PropTypes.string,
  attribution: PropTypes.string,
};

MenuQuoteSection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  logoImage: false,
  logoImageWidth: `${uc('112px')}`,
  personImage: false,
  personImageWidth: `${uc('145px')}`,
  link: 'https://csod.com',
  attribution: 'unknown',
};

export default MenuQuoteSection;
