import React, { useContext } from 'react';
import { css } from '@emotion/core';
import SVG from 'react-inlinesvg';
import { useStaticQuery, graphql } from 'gatsby';
import getOr from 'lodash/fp/getOr';
import Button from '../../atoms/Buttons/Button/Button';
import {
  breakpoints,
  colors,
  durations,
  fontFamilies,
  unitConverter as uc,
} from '../../../styles/base';
import useGoToSearch from '../../../hooks/useGoToSearch';
import HideWhenMatchesPath from '../../../hocs/HideWhenMatchesPath';
import { LocaleContext } from '../../../context/LocaleContext';
import useLocalize from '../../../hooks/useLocalize';

const searchIconSvg = '/svg/search.svg';

const MobileSearch = () => {
  const { onChange, onSearch, value } = useGoToSearch();
  const rawQueryResult = useStaticQuery(graphql`
    query {
      sanityStaticText(name: { eq: "searchInputPlaceholder" }) {
        content: _rawContent(resolveReferences: { maxDepth: 100 })
      }
    }
  `);
  const { locale } = useContext(LocaleContext);
  const queryResult = useLocalize(rawQueryResult, locale);

  const showSearchButton = event => {
    event.currentTarget.closest('form').classList.add('focused');
  };

  const hideSearchButton = event => {
    event.currentTarget.closest('form').classList.remove('focused');
  };

  const mobileSearchCss = css`
    position: absolute;
    display: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: ${uc('95px')};
    padding-right: ${uc('20px')};
    padding-left: ${uc('20px')};
    overflow: hidden;
    background-color: transparent;

    @media (${breakpoints.tablet}) {
      top: ${uc('-45px')};
      display: flex;
    }

    @media (${breakpoints.mobile}) {
      top: ${uc('-25px')};
      flex-direction: column;
      align-items: flex-end;
    }
  `;

  const searchInputContainerCss = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: ${uc('10px')};
    background-color: ${colors.white};
    border: ${uc('1px')} solid ${colors.lightGray.one};
    border-radius: ${uc('10px')};
  `;

  const iconCss = css`
    position: relative;
    margin-right: ${uc('5px')};
    fill: ${colors.primary};

    svg {
      width: ${uc('20px')};
      height: ${uc('20px')};
    }

    path {
      fill: ${colors.primary};
    }
  `;

  const inputCss = css`
    width: 100%;
    height: ${uc('40px')};
    color: ${colors.darkGray.three};
    font-size: ${uc('16px')};
    font-family: ${fontFamilies.roboto};
    line-height: normal;
    background-color: transparent;
    border: none;
    outline: none;

    ::placeholder {
      color: ${colors.lightGray.three};
      font-size: ${uc('16px')};
      font-family: ${fontFamilies.roboto};
    }
  `;

  const buttonContainerCss = css`
    margin-top: ${uc('20px')};
    margin-left: ${uc('10px')};

    @media (${breakpoints.mobile}) {
      margin-top: ${uc('10px')};
      transform: translateX(calc(100% + ${uc('20px')}));
      transition: ${durations.medium};
    }

    .focused & {
      @media (${breakpoints.mobile}) {
        transform: translateX(0);
      }
    }
  `;

  return (
    <HideWhenMatchesPath pattern="/search">
      <form onSubmit={onSearch} css={mobileSearchCss}>
        <div css={searchInputContainerCss}>
          <span css={iconCss}>
            <SVG src={searchIconSvg} />
          </span>
          <input
            css={inputCss}
            type="text"
            name="searchInput"
            value={value}
            placeholder={getOr('', 'sanityStaticText.content', queryResult)}
            onChange={onChange}
            onFocus={showSearchButton}
            onBlur={hideSearchButton}
          />
        </div>
        <div css={buttonContainerCss}>
          <Button
            type="submit"
            color="accent"
            onFocus={showSearchButton}
            onBlur={hideSearchButton}
          >
            search
          </Button>
        </div>
      </form>
    </HideWhenMatchesPath>
  );
};

export default MobileSearch;
