import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import productCategorySection from './ProductCategorySection/ProductCategorySection';
import suiteSection from './SuiteSection/SuiteSection';
import inlineLinkSection from './InlineLinkSection/InlineLinkSection';
import iconLinkSection from './IconLinkSection/IconLinkSection';
import iconLinkSubheadingSection from './IconLinkSubheadingSection/IconLinkSubheadingSection';
import industrySection from './IndustrySolutionsSection/IndustrySolutionsSection';
import groupedLinkSection from './GroupedLinkSection/GroupedLinkSection';
import menuQuoteSection from './MenuQuoteSection/MenuQuoteSection';
import {
  breakpoints,
  colors,
  unitConverter as uc,
} from '../../../../styles/base';

const Sections = {
  inlineLinkSection,
  productCategorySection,
  suiteSection,
  iconLinkSection,
  iconLinkSubheadingSection,
  industrySection,
  groupedLinkSection,
  menuQuoteSection,
};

const MenuSection = props => {
  const { _type } = props;
  const { _key: key } = props;
  let {
    menuSectionConfig: { backgroundColor },
  } = props;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const liCss = css`
    background-color: ${backgroundColor};

    @media (${breakpoints.largeTablet}) {
      width: ${uc('440px')};
      max-width: ${uc('440px')};
      margin-bottom: 0;
      padding-bottom: 0;
    }

    @media (${breakpoints.mobile}) {
      width: 100%;
    }
  `;

  return (
    <li key={key} css={liCss}>
      {Sections[_type]
        ? Sections[_type](props)
        : `MenuSection type ${_type} is missing`}
    </li>
  );
};

MenuSection.propTypes = {
  _type: PropTypes.string,
  _key: PropTypes.string,
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
};

MenuSection.defaultProps = {
  _type: '',
  _key: '',
  menuSectionConfig: { backgroundColor: 'white' },
};

export default MenuSection;
