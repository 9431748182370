import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
/** @jsx jsx needed to get Storybook to render */
import { jsx } from '@emotion/core';
import getOr from 'lodash/fp/getOr';
import { useContext } from 'react';
import Menu from './Menu';
import GatsbyGlobalMenu from '../GlobalMenu/GatsbyGlobalMenu';
import SearchModal from '../SearchModal/SearchModal';
import { LocaleContext } from '../../../context/LocaleContext';
import useLocalize from '../../../hooks/useLocalize';

const menuQuery = graphql`
  {
    sanityMenu(menuName: { eq: "Main Menu" }) {
      buttonText: _rawButtonText(resolveReferences: { maxDepth: 100 })
      buttonUrl {
        slug {
          current
        }
      }
      _rawMenuDrawers(resolveReferences: { maxDepth: 10 })
      useAngleOnHomepage
    }
  }
`;

const GatsbyMenu = ({ duration, menuColor, routeName }) => {
  const rawData = useStaticQuery(menuQuery) || {};
  const { locale } = useContext(LocaleContext);
  const { sanityMenu = {} } = useLocalize(rawData, locale);
  const {
    buttonText,
    buttonUrl = {},
    _rawMenuDrawers = [],
    useAngleOnHomepage,
  } = sanityMenu;
  const buttonLink = getOr('', 'slug.current', buttonUrl);

  const renderedGlobalMenu = (
    <GatsbyGlobalMenu menuColor={menuColor} duration={duration} />
  );

  const renderSearchModal = <SearchModal menuColor={menuColor} />;

  return (
    <Menu
      _rawMenuDrawers={_rawMenuDrawers}
      buttonText={buttonText || 'get demo'}
      buttonUrl={buttonLink}
      duration={duration}
      menuColor={menuColor}
      renderedGlobalMenu={renderedGlobalMenu}
      renderSearchModal={renderSearchModal}
      routeName={routeName}
      useAngleOnHomepage={useAngleOnHomepage}
    />
  );
};

GatsbyMenu.propTypes = {
  duration: PropTypes.number,
  menuColor: PropTypes.string,
  routeName: PropTypes.string,
};

GatsbyMenu.defaultProps = {
  duration: 300,
  menuColor: 'primary',
  routeName: '',
};

export default GatsbyMenu;
