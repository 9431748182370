/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';

const HideWhenMatchesPath = ({ children, pattern }) => (
  <Location>
    {({ location }) => {
      if (new RegExp(`^${pattern}`).test(location.pathname)) {
        return null;
      }
      return children;
    }}
  </Location>
);

HideWhenMatchesPath.propTypes = {
  pattern: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default HideWhenMatchesPath;
