import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import SVG from 'react-inlinesvg';
import Link from '../../atoms/Link/Link';
import { Heading3 } from '../../atoms/Headings/Headings';
import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  menuBreakpoints,
  menuColors,
  unitConverter as uc,
} from '../../../styles/base';

const GlobalMenu = ({ _rawGlobalMenuLinks, duration, menuColor }) => {
  const globeIconSvg = '/svg/globe-icon.svg';

  // uses 1127px as a breakpoint because that's when the menu starts to wrap,
  // so we need to start using MobileMenu then
  const globalMenuCss = css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: ${uc('20px')};
    margin: ${uc('0 20px')};
    perspective: ${uc('1500px')};
    cursor: pointer;

    &:hover {
      div {
        pointer-events: auto;
      }

      ul {
        transform: rotateX(0);
        opacity: 1;
        pointer-events: auto;
      }

      svg {
        fill: ${menuColors.whiteHover};
      }
    }

    @media (${menuBreakpoints.completeSwitchToMobile}) {
      display: none;
    }
  `;

  const dropdownCss = css`
    position: absolute;
    top: ${uc('20px')};
    left: ${uc('-130px')};
    padding-top: ${uc('20px')};
    pointer-events: none;
  `;

  const iconCss = css`
    position: relative;
    fill: ${menuColor ? menuColors[menuColor] : menuColors.white};

    svg {
      position: absolute;
      top: ${uc('8px')};
      width: ${uc('20px')};
      height: ${uc('20px')};
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 0.5;
    }
  `;

  const listCss = css`
    width: ${uc('280px')};
    padding: ${uc('20px 30px')};
    background-color: ${colors.white};
    border-radius: ${uc('6px')};
    box-shadow: ${uc('0 50px 40px')} rgba(50, 50, 93, 0.1),
      ${uc('0 0 100px')} rgba(50, 50, 93, 0.1);
    transform: rotateX(-15deg);
    transform-origin: center top;
    opacity: 0;
    transition: ${duration}ms;
    pointer-events: none;

    &::after {
      position: absolute;
      top: ${uc('-11px')};
      left: 45%;
      display: block;
      width: ${uc('30px')};
      height: ${uc('17px')};
      background-image: url('/menuCarets/menu-caret--white.png');
      background-repeat: no-repeat;
      content: '';
    }

    li {
      margin: ${uc('7px 0')};
      list-style-type: none;
    }
  `;

  const headingCss = css`
    margin: 0;
    color: ${colors.primary};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.copy};
    letter-spacing: ${uc('1px')};

    &:hover {
      color: ${colors.primaryHover};
    }
  `;

  return (
    <div css={globalMenuCss} className="global-menu-d7">
      <span css={iconCss}>
        <SVG src={globeIconSvg} />
      </span>
      <div css={dropdownCss}>
        <ul css={listCss}>
          {_rawGlobalMenuLinks.length > 0 &&
            _rawGlobalMenuLinks.map(menuLink => (
              <li key={menuLink._key}>
                {menuLink.link && menuLink.link.length ? (
                  <Link to={menuLink} css={headingCss}>
                    {menuLink.text}
                  </Link>
                ) : (
                  <Heading3 css={headingCss}>{menuLink.text}</Heading3>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

GlobalMenu.propTypes = {
  _rawGlobalMenuLinks: PropTypes.arrayOf(PropTypes.shape({})),
  duration: PropTypes.number,
  menuColor: PropTypes.string,
};

GlobalMenu.defaultProps = {
  _rawGlobalMenuLinks: [],
  duration: 300,
  menuColor: 'primary',
};

export default GlobalMenu;
