import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';

import Link from '../../atoms/Link/Link';
import { Heading2 } from '../../atoms/Headings/Headings';
import Subheading from '../../atoms/Subheading/Subheading';
import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';

// apply css
const inlineLinkSubheadingCss = css`
  display: block;
  margin-bottom: ${uc('3.6px')};
  padding: ${uc('5px 30px')};

  &:first-of-type {
    padding-top: ${uc('30px')};
  }

  &:last-of-type {
    padding-bottom: ${uc('30px')};
  }

  a,
  p {
    text-decoration: none;
  }

  h2,
  p {
    display: inline-block;
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.roboto};
  }

  h2 {
    margin: 0 ${uc('5px')} 0 0;
    color: ${colors.primary};
    font-weight: ${fontWeights.medium};
    letter-spacing: 0;

    &:active,
    &:focus,
    &:hover {
      color: ${colors.primaryHover};
    }
  }
`;

// render heading and subheading inside a link
const InlineLinkSubheading = ({ headingText, link, subheadingText }) => (
  <Link to={link} css={inlineLinkSubheadingCss}>
    <Heading2 color={colors.primary}>{headingText}</Heading2>
    <Subheading color={colors.mediumGray}>{subheadingText}</Subheading>
  </Link>
);

InlineLinkSubheading.propTypes = {
  link: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  headingText: PropTypes.string,
  subheadingText: PropTypes.string,
};

InlineLinkSubheading.defaultProps = {
  link: '/',
  headingText: 'Heading here',
  subheadingText: 'Subheading here',
};

export default InlineLinkSubheading;
