import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  buttonColors,
  fontFamilies,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../../styles/base';
import Link from '../../Link/Link';

const AngledButton = ({
  _key,
  angle,
  buttonText,
  className,
  color,
  link,
  target,
}) => {
  const buttonCss = css`
    display: inline-block;
    height: ${uc('43.19px')};
    margin-right: ${uc('10px')};
    margin-bottom: ${uc('20px')};
    padding: ${uc('12px 20px')};
    overflow: hidden;

    color: ${buttonColors[color].color};
    font-weight: ${fontWeights.semiBold};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.title};
    letter-spacing: ${uc('0.5px')};
    text-transform: lowercase;
    text-decoration: none;

    background: ${buttonColors[color].background};
    border: none;

    cursor: pointer;
    transition: 0.3s;
    clip-path: ${angle === 'top'
      ? 'polygon(0 0, 100% 10%, 100% 100%, 0 100%)'
      : 'polygon(0 0, 100% 0, 100% 100%, 0 90%)'};

    &:active,
    &:focus,
    &:hover {
      color: ${buttonColors[color].colorHover};
      background: ${buttonColors[color].backgroundHover};
      border-color: ${buttonColors[color].backgroundHover};
    }

    @media (${breakpoints.mobile}) {
      display: block;
      width: 100%;
      margin-right: 0;
    }
  `;

  const buttonID = `button-id-${_key}`;
  if (link) {
    return (
      <Link
        key={_key}
        type="button"
        css={buttonCss}
        to={link}
        className={[buttonID, className].join(' ')}
        target={target}
      >
        {link.text || buttonText}
      </Link>
    );
  }
  return (
    <button
      key={_key}
      type="button"
      css={buttonCss}
      className={[buttonID, className].join(' ')}
      target={target}
    >
      {buttonText}
    </button>
  );
};

AngledButton.propTypes = {
  _key: PropTypes.string,
  angle: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  target: PropTypes.string,
};

AngledButton.defaultProps = {
  _key: '',
  angle: 'bottom',
  buttonText: null,
  className: '',
  color: 'gray',
  link: false,
  target: null,
};

export default AngledButton;
