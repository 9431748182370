import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  colors,
  sectionSettings,
  unitConverter as uc,
} from '../../../../../styles/base';
import IconLink from '../../../../molecules/IconLink/IconLink';

const IconLinkSection = ({ menuSectionConfig = [], iconLinks = [] }) => {
  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const sectionCss = css`
    display: flex;
    flex-flow: wrap column;
    max-height: ${uc('410px')};
    padding-left: ${menuSectionConfig.leftPadding
      ? sectionSettings.menuPadding
      : '0px'};
    background: ${backgroundColor};
  `;

  const renderedLinks = iconLinks.map(item => (
    <IconLink key={item._key} {...item} />
  ));

  return <div css={sectionCss}>{renderedLinks}</div>;
};

IconLinkSection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  iconLinks: PropTypes.arrayOf(PropTypes.shape({})),
};

IconLinkSection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  iconLinks: [],
};

export default IconLinkSection;
