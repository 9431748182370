import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../molecules/SearchIcon/SearchIcon';
import Modal from '../../molecules/Modal/Modal';
import SearchInput from '../../molecules/SearchInput/SearchInput';
import HideWhenMatchesPath from '../../../hocs/HideWhenMatchesPath';

const SearchModal = ({ menuColor }) => {
  const [state, setState] = useState({ visible: false, value: '' });
  const { value, visible } = state;

  const toggleModal = () => {
    setState({ visible: !state.visible });
  };

  const onChangeText = event => {
    setState({
      ...state,
      value: event.target.value,
    });
  };
  return (
    <HideWhenMatchesPath pattern="/search">
      <SearchIcon color={menuColor} onClick={toggleModal} />
      <Modal visible={visible} onClose={toggleModal} dataCy="search-modal">
        <SearchInput
          value={value}
          placeholder="Search something"
          onChange={onChangeText}
        />
      </Modal>
    </HideWhenMatchesPath>
  );
};

SearchModal.propTypes = {
  menuColor: PropTypes.string,
};

SearchModal.defaultProps = {
  menuColor: 'primary',
};

export default SearchModal;
