/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  colors,
  fontSizes,
  unitConverter as uc,
} from '../../../../styles/base';

const Navbar = ({ children, onMouseLeave }) => {
  const navCss = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    div + li {
      margin-left: ${uc('10px')};
      padding: ${uc('1px 7px 2px')};
      list-style: none;
    }

    div + li a {
      text-decoration: none;
    }

    div + li a p {
      font-size: ${fontSizes.sixteen};
    }

    div + li a p:hover,
    div + li a p:active,
    div + li a p:focus {
      margin: 0;
      color: ${colors.accentHover};
    }
  `;

  return (
    <nav css={navCss} onMouseLeave={onMouseLeave}>
      {children}
    </nav>
  );
};

Navbar.propTypes = {
  onMouseLeave: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Navbar;
