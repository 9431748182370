/* eslint-disable jsx-a11y/no-autofocus */
/** @jsx jsx */
import { useRef, useEffect, useContext } from 'react';
import { css, jsx } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import getOr from 'lodash/fp/getOr';
import Button from '../../atoms/Buttons/Button/Button';
import Text from '../../atoms/Text/Text';
import {
  colors,
  fontFamilies,
  unitConverter as uc,
} from '../../../styles/base';
import useGoToSearch from '../../../hooks/useGoToSearch';
import { LocaleContext } from '../../../context/LocaleContext';
import useLocalize from '../../../hooks/useLocalize';

const inputContainerCss = css`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100vw - ${uc('50px')});
  background-color: transparent;
`;

const inputCss = css`
  width: 100%;
  max-width: ${uc('800px')};
  height: ${uc('70px')};
  margin-right: ${uc('20px')};
  margin-bottom: ${uc('20px')};
  color: ${colors.white};
  font-size: ${uc('42px')};
  font-family: ${fontFamilies.proximaSoft};
  line-height: normal;
  background-color: transparent;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-color: ${colors.white};
  border-left-width: 0;
  outline: none;
  -webkit-appearance: none;
`;

const buttonCss = css`
  flex-shrink: 0;
`;

const textContainerCss = css`
  position: absolute;
  bottom: ${uc('-35px')};
  left: ${uc('0px')};
`;

const SearchInput = () => {
  const rawQueryResult = useStaticQuery(graphql`
    query {
      sanityStaticText(name: { eq: "searchInputPlaceholder" }) {
        content: _rawContent(resolveReferences: { maxDepth: 100 })
      }
    }
  `);
  const { locale } = useContext(LocaleContext);
  const queryResult = useLocalize(rawQueryResult, locale);
  const { error, onChange, onSearch, value } = useGoToSearch();

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <form onSubmit={onSearch}>
      <div css={inputContainerCss}>
        <input
          ref={inputRef}
          type="text"
          name="searchInput"
          value={value}
          placeholder={getOr('', 'sanityStaticText.content', queryResult)}
          css={inputCss}
          onChange={onChange}
        />
        <Button
          _key="submit-search"
          type="submit"
          color="accent"
          data-cy="submit-search"
          css={buttonCss}
        >
          search
        </Button>
        {error && (
          <div css={textContainerCss}>
            <Text color={colors.accent} fontSize={uc('22px')}>
              {error}
            </Text>
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchInput;
