import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  durations,
  fontFamilies,
  fontSizes,
  unitConverter as uc,
} from '../../../styles/base';
import Link from '../../atoms/Link/Link';
import { Heading2 } from '../../atoms/Headings/Headings';
import Subheading from '../../atoms/Subheading/Subheading';

const LinkSubheading = props => {
  const {
    headingText,
    subheadingText,
    subheadingTextHighlight,
    link,
    HeadingType = Heading2,
  } = props;

  const linkSubheadingCss = css`
    display: flex;
    min-width: ${uc('300px')};
    padding: ${uc('10px 30px 0 30px')};
    text-decoration: none;

    &:first-of-type {
      padding-top: 0;
    }

    &:hover {
      cursor: pointer;

      ${HeadingType} {
        color: ${colors.primaryHover};
      }

      ${Subheading} {
        color: ${colors.darkGray.three};
      }
    }

    ${HeadingType},
    ${Subheading} {
      transition: ${durations.fast};
    }

    ${HeadingType} {
      letter-spacing: normal;
      white-space: nowrap;
    }

    ${Subheading} {
      white-space: normal;

      @media (${breakpoints.largeTablet}) {
        padding: 0;
      }
    }
  `;

  const headingCss = css`
    margin-bottom: 0;
    color: ${colors.primary};
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.proximaSoft};

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.fifteen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fifteen};
    }
  `;

  const subheadingCss = css`
    padding-bottom: ${uc('20px')};
    font-weight: 500;

    @media (${breakpoints.largeTablet}) {
      span {
        padding: 0;
      }
    }
  `;

  const subheadingHighlightCss = css`
    color: ${colors.darkGray.three};
  `;

  const textBlockCss = css`
    line-height: 1.6;
  `;

  return (
    <Link to={link} css={linkSubheadingCss} {...props}>
      <div css={textBlockCss}>
        <HeadingType css={headingCss} {...props}>
          {headingText}
        </HeadingType>
        {subheadingText && (
          <Subheading
            css={subheadingCss}
            fontSize={fontSizes.fourteen}
            color={colors.mediumGray}
          >
            {subheadingText}
            <span css={subheadingHighlightCss}>
              {` ${subheadingTextHighlight}`}
            </span>
          </Subheading>
        )}
      </div>
    </Link>
  );
};

LinkSubheading.propTypes = {
  headingText: PropTypes.string.isRequired,
  subheadingTextHighlight: PropTypes.string,
  subheadingText: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  HeadingType: PropTypes.shape({}),
};

LinkSubheading.defaultProps = {
  subheadingTextHighlight: '',
  subheadingText: null,
  HeadingType: Heading2,
};

export default LinkSubheading;
