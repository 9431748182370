/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  breakpoints,
  menuColors,
  unitConverter as uc,
} from '../../../styles/base';

const searchIconSvg = '/svg/search.svg';

const SearchIcon = ({ color, onClick }) => {
  const iconCss = css`
    position: relative;
    fill: ${color ? menuColors[color] : menuColors.white};

    svg {
      position: relative;
      top: ${uc('2px')};
      width: ${uc('20px')};
      height: ${uc('20px')};
    }

    path {
      fill: ${menuColors ? menuColors[color] : menuColors.primary};
    }
  `;

  const iconContainerCss = css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: ${uc('20px')};
    width: auto;
    margin-left: ${uc('20px')};
    padding: 0;
    background-color: ${menuColors.transparent};
    border: none;
    outline: none;
    cursor: pointer;

    @media (${breakpoints.mobile}) {
      display: none;
    }

    @media (${breakpoints.tablet}) {
      display: none;
    }

    @media (${breakpoints.largeTablet}) {
      margin-top: 0;
    }
  `;

  return (
    <button
      css={iconContainerCss}
      onClick={onClick}
      type="button"
      onKeyDown={() => null}
      data-cy="search-button"
    >
      <span css={iconCss}>
        <SVG src={searchIconSvg} />
      </span>
    </button>
  );
};

SearchIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
};

SearchIcon.defaultProps = {
  color: 'primary',
  onClick: () => null,
};

export default SearchIcon;
