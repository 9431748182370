import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  sectionSettings,
  unitConverter as uc,
} from '../../../../../styles/base';
import { Heading2 } from '../../../../atoms/Headings/Headings';
import Link from '../../../../atoms/Link/Link';

const IndustrySolutionsSection = ({
  heading,
  industryLinks,
  menuSectionConfig,
}) => {
  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const sectionCss = css`
    width: ${uc('435px')};
    padding-bottom: ${uc('30px')};
    padding-left: ${menuSectionConfig.leftPadding
      ? sectionSettings.menuPadding
      : '0px'};
    background: ${backgroundColor};

    @media (${breakpoints.tablet}) {
      padding-bottom: ${uc('10px')};
    }
  `;

  const headingCss = css`
    margin-top: ${uc('20px')};
    padding: ${uc('10px 38px 0')};
    color: ${colors.darkGray.three};
    font-size: ${fontSizes.fourteen};
    font-family: ${fontFamilies.roboto};
    text-transform: uppercase;

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.fourteen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fourteen};
    }
  `;

  const linkCss = css`
    display: block;
    margin-bottom: ${uc('12px')};
    color: ${colors.primary};
    font-size: ${fontSizes.fourteen};
    font-family: ${fontFamilies.roboto};
    text-decoration: none;

    &:hover {
      color: ${colors.primaryHover};
      cursor: pointer;
    }
  `;

  const linkContainerCss = css`
    padding: ${uc('0 38px')};
    columns: 2;
    -moz-column-count: 2; /* Firefox */

    @media (${breakpoints.mobile}) {
      columns: 1;
    }
  `;

  const renderedLinks = industryLinks.map((item, index) => {
    const currentIndex = `industry-link-${index}`;

    // Link is expecting { link: [ {linkObj} ] } format
    const formattedLink = {
      link: item.link,
    };

    return (
      <Link to={formattedLink} css={linkCss} {...item} key={currentIndex}>
        {item.text}
      </Link>
    );
  });

  return (
    <div css={sectionCss}>
      <Heading2 css={headingCss} className="industry-solutions-heading-d7">
        {heading}
      </Heading2>
      <div css={linkContainerCss}>{renderedLinks}</div>
    </div>
  );
};

IndustrySolutionsSection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  heading: PropTypes.string,
  industryLinks: PropTypes.arrayOf(PropTypes.shape({})),
};

IndustrySolutionsSection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  heading: '',
  industryLinks: [],
};

export default IndustrySolutionsSection;
