/** @jsx jsx this comment is required for Storybook to render */
import { jsx, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { Children, createRef } from 'react';
import PropTypes from 'prop-types';
import { Flipped } from 'react-flip-toolkit';
import { colors, unitConverter as uc } from '../../../../styles/base';
import FadeContents from './FadeContents';

const getDropdownRootKeyFrame = ({ animatingOut, direction }) => {
  if (!animatingOut && direction) return null;
  return keyframes`
    from {
      transform: ${animatingOut ? 'rotateX(0)' : 'rotateX(-15deg)'};
      opacity: ${animatingOut ? 1 : 0};
    }
    to {
      transform: ${animatingOut ? 'rotateX(-15deg)' : 'rotateX(0)'};
      opacity: ${animatingOut ? 0 : 1};
    }
  `;
};

const DropdownRoot = styled.div`
  position: relative;
  top: ${uc('-15px')};

  /* flex styles will center the caret child component */
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: 0 0;
  animation-name: ${getDropdownRootKeyFrame};
  animation-duration: ${props => props.duration}ms;

  /* use 'forwards' to prevent flicker on leave animation */
  animation-fill-mode: forwards;
`;

const DropdownBackground = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};
  border-radius: ${uc('6px')};
  box-shadow: ${uc('0 50px 40px')} rgba(50, 50, 93, 0.1),
    ${uc('0 0 100px')} rgba(50, 50, 93, 0.1);
  transform-origin: 0 0;
  will-change: transform;
`;

const InvertedDiv = styled.div`
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  top: 0;
  left: 0;

  &:first-of-type {
    z-index: 1;
  }

  &:not(:first-of-type) {
    z-index: -1;
  }
`;

const DropdownContainer = ({
  animatingOut,
  children,
  direction,
  duration,
  triangleColor,
}) => {
  const [currentDropdown, prevDropdown] = Children.toArray(children);
  const currentDropdownEl = createRef();
  const prevDropdownEl = createRef();

  const Caret = styled.div`
    position: relative;

    /* prevent any gap in between caret and main dropdown */
    top: ${uc('5px')};

    /* make sure it's above the main dropdown container so no box-shadow bleeds over it */
    z-index: 1;
    width: ${uc('30px')};
    height: ${uc('17px')};
    background-image: url('/menuCarets/menu-caret--${triangleColor}.png');
    background-repeat: no-repeat;
  `;

  return (
    <DropdownRoot
      direction={direction}
      animatingOut={animatingOut}
      duration={duration}
    >
      <Flipped flipId="dropdown-caret">
        <Caret />
      </Flipped>
      <Flipped flipId="dropdown">
        <DropdownBackground>
          <Flipped inverseFlipId="dropdown">
            <InvertedDiv>
              <FadeContents
                direction={direction}
                duration={duration}
                ref={currentDropdownEl}
              >
                {currentDropdown}
              </FadeContents>
            </InvertedDiv>
          </Flipped>

          <Flipped inverseFlipId="dropdown" scale>
            <InvertedDiv absolute>
              {prevDropdown && (
                <FadeContents
                  animatingOut
                  direction={direction}
                  duration={duration}
                  ref={prevDropdownEl}
                >
                  {prevDropdown}
                </FadeContents>
              )}
            </InvertedDiv>
          </Flipped>
        </DropdownBackground>
      </Flipped>
    </DropdownRoot>
  );
};

DropdownContainer.propTypes = {
  children: PropTypes.node.isRequired,
  animatingOut: PropTypes.bool.isRequired,
  direction: PropTypes.oneOf(['left', 'right', null]),
  duration: PropTypes.number,
  triangleColor: PropTypes.string,
};

DropdownContainer.defaultProps = {
  direction: null,
  duration: 300,
  triangleColor: 'white',
};

export default DropdownContainer;
