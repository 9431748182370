/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  breakpoints,
  colors,
  rgba,
  unitConverter as uc,
} from '../../../styles/base';

const closeIconSvg = '/svg/close-button.svg';

const Modal = ({ backgroundColor, children, dataCy, onClose, visible }) => {
  const show2 = css`
    transform: translateY(0);
  `;

  const modalContainerCss = css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: ${backgroundColor};
    transform: translateY(-100%);
    transition: transform 0.5s ease;

    @media (${breakpoints.tablet}) {
      top: ${uc('-1100px')};
    }

    ${visible ? show2 : ''}
  `;

  const closeBtnCss = css`
    position: absolute;
    top: ${uc('20px')};
    right: ${uc('20px')};
    z-index: 101;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      width: ${uc('20px')};
      height: ${uc('20px')};

      .st0 {
        fill: ${colors.white};
        stroke: ${colors.white};
      }
    }
  `;

  return (
    <div css={modalContainerCss} data-cy={dataCy}>
      <button
        className="close-button"
        type="button"
        css={closeBtnCss}
        onClick={onClose}
      >
        <SVG src={closeIconSvg} />
      </button>
      {children}
    </div>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool,
  backgroundColor: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  dataCy: PropTypes.string,
};

Modal.defaultProps = {
  visible: false,
  backgroundColor: `rgba(${rgba(colors.primary, '0.84')})`,
  onClose: () => null,
  children: null,
  dataCy: 'modal',
};

export default Modal;
