import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
/** @jsx jsx needed to get Storybook to render */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import GlobalMenu from './GlobalMenu';
import useLocalize from '../../../hooks/useLocalize';
import { LocaleContext } from '../../../context/LocaleContext';

const globalMenuQuery = graphql`
  {
    sanityGlobalMenu(globalMenuName: { eq: "Global Menu" }) {
      _rawGlobalMenuLinks(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const GatsbyGlobalMenu = ({ duration, menuColor }) => {
  const rawData = useStaticQuery(globalMenuQuery) || {};
  const { localize } = useContext(LocaleContext);
  const { sanityGlobalMenu = {} } = useLocalize(rawData, localize);
  const { _rawGlobalMenuLinks = [] } = sanityGlobalMenu;

  return (
    <div>
      <GlobalMenu
        _rawGlobalMenuLinks={_rawGlobalMenuLinks}
        duration={duration}
        menuColor={menuColor}
      />
    </div>
  );
};

GatsbyGlobalMenu.propTypes = {
  duration: PropTypes.number,
  menuColor: PropTypes.string,
};

GatsbyGlobalMenu.defaultProps = {
  duration: 300,
  menuColor: 'primary',
};

export default GatsbyGlobalMenu;
