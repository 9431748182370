import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  unitConverter as uc,
} from '../../../../../styles/base';
import { Heading2, Heading3 } from '../../../../atoms/Headings/Headings';
import LinkSubheading from '../../../../molecules/LinkSubheading/LinkSubheading';

const GroupedLinkSection = ({ groupedLinks, menuSectionConfig }) => {
  let { backgroundColor } = menuSectionConfig;
  backgroundColor =
    backgroundColor === 'lightGray' ? colors.lightGray.six : colors.white;

  const sectionCss = css`
    padding-bottom: ${uc('20px')};
    background: ${backgroundColor};

    @media (${breakpoints.tablet}) {
      padding-bottom: ${uc('10px')};
    }
  `;

  const heading2Css = css`
    padding: ${uc('30px 20px 0 30px')};
    color: ${colors.lightGray.five};
    font-size: ${fontSizes.fifteen};
    font-family: ${fontFamilies.roboto};
    letter-spacing: normal;
    text-transform: uppercase;

    @media (${breakpoints.tablet}) {
      margin-bottom: ${uc('10px')};
      padding-top: ${uc('10px')};
      font-size: ${fontSizes.fifteen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fifteen};
    }
  `;

  const { groupHeading, links } = groupedLinks;

  const renderedGroupedLinks = links.map((item, index) => {
    const currentIndex = `link-${index}`;
    return (
      <LinkSubheading
        image=""
        noImage
        HeadingType={Heading3}
        {...item}
        key={currentIndex}
      />
    );
  });

  return (
    <div css={sectionCss}>
      <Heading2 css={heading2Css} className="grouped-link-section-heading-d7">
        {groupHeading}
      </Heading2>
      {renderedGroupedLinks}
    </div>
  );
};

GroupedLinkSection.propTypes = {
  menuSectionConfig: PropTypes.shape({
    backgroundColor: PropTypes.string,
    leftPadding: PropTypes.bool,
  }),
  groupedLinks: PropTypes.shape({
    groupHeading: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

GroupedLinkSection.defaultProps = {
  menuSectionConfig: {
    backgroundColor: colors.white,
    leftPadding: false,
  },
  groupedLinks: [],
};

export default GroupedLinkSection;
