import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import SVG from 'react-inlinesvg';
import {
  colors,
  fontFamilies,
  fontSizes,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading2 } from '../../atoms/Headings/Headings';
import Link from '../../atoms/Link/Link';
import sanityImage from '../../../utils/sanityImage';

const IconLink = ({ headingText, image, imageHeight, imageWidth, link }) => {
  const linkCss = css`
    display: flex;
    align-items: center;
    padding: ${uc('10px 10px 5px 30px')};
    text-decoration: none;

    &:first-of-type {
      padding-top: ${uc('30px')};
    }

    &:nth-of-type(6) {
      padding-bottom: ${uc('30px')};
    }

    &:nth-of-type(7) {
      padding-top: ${uc('30px')};
    }

    svg {
      .st0 {
        fill: none;
        stroke: ${colors.primary};
      }
    }
  `;

  const imageCss = css`
    display: inline-block;
    width: ${uc(imageWidth)};
    height: ${uc(imageHeight)};
    margin-right: ${uc('10px')};
    vertical-align: middle;
  `;

  const headingCss = css`
    display: inline-block;

    h2 {
      margin-bottom: 0;
      color: ${colors.primary};
      font-size: ${fontSizes.sixteen};
      font-family: ${fontFamilies.roboto};
      letter-spacing: normal;
    }
  `;

  return (
    <Link css={linkCss} to={link}>
      <div css={imageCss}>
        <SVG
          src={sanityImage(image)
            .auto('format')
            .url()}
          cacheRequests={false}
        />
      </div>
      <div css={headingCss}>
        <Heading2>{headingText}</Heading2>
      </div>
    </Link>
  );
};

IconLink.propTypes = {
  image: PropTypes.shape({
    _type: PropTypes.string.isRequired,
    asset: PropTypes.shape({}),
  }),
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  headingText: PropTypes.string,
  link: PropTypes.string,
};

IconLink.defaultProps = {
  image: '',
  imageWidth: '42px',
  imageHeight: '42px',
  headingText: 'headingText',
  link: '/',
};

export default IconLink;
